@import "~bootstrap/scss/bootstrap";
@import "./variables";

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
}

.modal {
  @include for-mobile-only {
    padding-right: 0 !important;
  }
  .login-modal, .forgotPassword-modal, .confirm-modal {
    max-width: 443px;
    margin-top: 128px;
    .modal-content {
      border-radius: 10px;
      border: none;
      &.no-header {
        padding-top: 16px;
      }
    }
    .modal-body {
      padding: 26px 42px 50px 42px;
    }
    .modal-footer {
      padding: 43px;
    }
  }
  .confirm-modal, .scan-modal {
    max-width: 480px;
    .modal-content {
      border-radius: 10px;
      border: none;
      padding: 45px 45px 48px 40px;
    }
    .modal-body {
      margin-top: 24px;
      padding: 0px;
    }
    .modal-header .close {
      position: relative;
      top: 0px;
      right: 0px;
    }
  }
  .scan-modal {
    max-width: 760px;
    .modal-content {
      padding: 40px;
    }
    .modal-body {
      margin-top: 24px;
    }
  }
  .invite-status-modal {
    min-width: 448px;
    .modal-body {
      padding: 32px;
    }
    .modal-header {
      margin: 10px;
    }
  }
  .invite-modal {
    max-width: 443px;
    margin-top: 128px;
    .invite-modal-content {
      border-radius: 10px;
      border: none;
    }
    .modal-body {
      padding: 26px 42px 50px 42px;
    }
    .modal-footer {
      height: 30px;
      border-top: none;
      justify-content: flex-start;
    }
  }
  .close {
    background: url('../images/close.svg') no-repeat center;
    opacity: 1 !important;
    padding: 15px;
    position: relative;
    top: 12px;
    right: 16px;
    outline: none;
    span {
      display: none;
    }
  }
  .modal-header {
    border: none;
  }

  .modal-header.profile-header {
    background: #f3f3f3;
    padding: 32px 28px;
  }

  .snackbar-modal.modal-dialog {
    border: 2px solid #02bf8e;
    background: #FFFFFF;
    border: 1.5px solid #02BF8E;
    box-sizing: border-box;
    box-shadow: 0px 4px 24px #DDDDDD;
    border-radius: 10px;
    .modal-body {
      padding: 0px;
    }
  }

  .calculator-modal.modal-dialog {
    height: 348px;
    .modal-body {
      padding: 30px 37px 28px 42px;
    }
  }

  .add-event-modal {
    width: 626px;
  }

  .employee-profile-modal.modal-dialog .modal-body {
    padding: 30px 37px 28px 42px;
  }

  @include for-mobile-only {
    .calculator-modal.modal-dialog {
      .modal-body {
        padding: 15px 30px;
      }
    }
    .employee-profile-modal .modal-content {
      // width: 90%;
    }
    .invite-status-modal {
      min-width: auto;
      .modal-body {
        padding: 15px 30px;
      }
    }
  }
  .employee-profile-modal .modal-content {
    // width: 648px;
    min-height: 300px;
  }
}

input::-moz-placeholder {
  color: $placeholder-color !important;
  font-family: 'futura-pt', sans-serif;
}

input:-ms-input-placeholder {
  color: $placeholder-color !important;
  font-family: 'futura-pt', sans-serif;
}

input::-webkit-input-placeholder {
  color: $placeholder-color !important;
  font-family: 'futura-pt', sans-serif;
}

input::placeholder {
  color: $placeholder-color !important;
  font-family: 'futura-pt', sans-serif;
}

a {
  &:hover {
    color: $base-color !important;
    text-decoration: inherit !important;
  }
}

.logout-btn.dropdown {
  position: inherit;
  .dropdown-toggle {
    background-color: transparent;
    border: none;
    padding: 0;
    font-weight: normal;
    font-size: 16px;
    line-height: 23px;
    &::after {
      display: none;
    }
    &:hover,
    &:focus,
    &:active {
      background-color: transparent !important;
      box-shadow: none !important;
    }
  }
  .dropdown-menu {
    padding: 0;
    background: $green-dark;
    font-size: 16px;
    line-height: 23px;
    border: none;
    border-radius: 0;
    max-width: 173px;
    top: 23px !important;
  }
  .dropdown-item {
    color: $white-color;
    background: $green-dark;
    font-weight: bold;
    display: flex;
    align-items: center;
    justify-content: center;
    min-height: 48px;
    &:hover,
    &:focus,
    &:active {
      background: $green-dark;
      color: $white-color !important;
    }
  }
}

.btn-success:not(:disabled):not(.disabled).active,
.btn-success:not(:disabled):not(.disabled):active,
.show>.btn-success.dropdown-toggle {
  background-color: transparent !important;
}

.menu-collapsed {
  height: 100vh;
  .navbar-brand {
    display: none;
  }
  .navbar-expand-lg {
    justify-content: flex-end;
    height: 100vh;
    align-items: flex-start;
    padding-bottom: 50px !important;
    span {
      padding: 8px 0 0;
    }
  }
}

.action-active{
  display:none;
}
.table-tr.clickable{
  cursor: pointer;
}
.table-tr:hover .action-active{
  display:block;
}
.table-tr:hover .action-inactive{
  display:none;
}
.stats-tooltip .tooltip-inner {
  background: #565655;
  font-size: 12px;
  padding: 8px 16px;
  color: #FFFFFF;
  max-width: 280px;
}
.stats-tooltip .arrow {
 display: none;
}
#temperature-tooltip-button, #wellBeing-tooltip-button {
  top: 30px !important;
}

.vat-modal {
  max-width: 408px;
  .modal-body {
    padding: 40px 24px 24px 24px;
  }
}

.vat-modal .modal-content {
  border: none;
  border-radius: 10px;
  .modal-body {
    padding: 40px 24px 24px 24px;
  }
}

.rc-switch.custom {
  width: 48px;
  height: 30px;
  background-color: #F3F3F3;
  border: none;

  &:after {
    width: 24px;
    height: 25px;
    top: 2px;

    box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.1);
  }

  &.rc-switch-checked {
    background-color: #02BF8E;
    &:after {
      left: 21px;
    }
  }
}

.rc-switch.new-custom {
  width: 48px;
  height: 24px;
  background-color: #F0F1F2;
  border: none;
  border-radius: 6px;
  display: flex;
  justify-content: center;
  align-items:center;

  font-family: 'futura-pt';
  font-style: normal;
  font-weight: 450;
  font-size: 12px;
  line-height: 16px;

  & span {
    top: 4px;
    left: 8px;
  }

  &:after {
    transition: width 0.1s linear 0.1s, left 0.2s linear;
    top: 3px;
    left: 3px;
    width: 18px;
    height: 18px;
    background: #FFFFFF;
    box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.1);
    border-radius: 5px;
  }

  &.rc-switch-checked {
    background-color: #02BF8E;
    &:after {
      left: 33px;
      width: 9px;
      border-radius: 8px;
    }
  }

  &:focus {
    box-shadow: none;
  }
}

.react-datepicker__input-container > input {
  border-color: #8D8C91;
}

.tippy-box[data-theme~='testedme_dark'] {
  background-color: #4D515E;
  color: white;
  font-family: 'futura-pt', sans-serif;
  font-style: normal;
  font-weight: 450;
  font-size: 12px;
  line-height: 16px;
}

.mobileOrderContainer {
  margin-top: 136px;
  margin-bottom: 0px;

  @media (max-width: 575px) {
    margin-left: 0px;
    margin-right: 0px;
  }
}

.mobileOrderContent {
  height: calc(100vh - 136px);
  border: none;
  border-radius: 10px 10px 0px 0px;
  box-shadow: 0px 4px 16px rgba(0, 0, 0, 0.05),
  0px 12px 48px rgba(0, 0, 0, 0.25);
}

.mobileResultContainer {
  margin-top: 136px;
  margin-bottom: 0px;

  @media (max-width: 575px) {
    width: 100%;
    margin-left: 0px;
    margin-right: 0px;
  }
}

.mobileResultContent {
  height: calc(100vh - 136px);
  border: none;
  border-radius: 10px 10px 0px 0px;
  box-shadow: 0px 4px 16px rgba(0, 0, 0, 0.05),
  0px 12px 48px rgba(0, 0, 0, 0.25);
}

.resultsModalContainer {
  width: 640px;

  @media (max-width: 575px) {
    width: 100%;
    margin-left: 0px;
    margin-right: 0px;
  }
}

.resultsModalContainerWithImage {
  width: 640px;

  &.mobileResultContent{
    width: 100vw;
  }
}

.resultsPreviewFullscreen {
  width: 100%;
  padding: 10px;
    position: relative;
    margin: auto;

  .modal-content {
    background-color: transparent;
    width: 100%;
    border: none;
    margin: 0;
    overflow: auto;
    position: relative;
  }
}

div.fade.modal.show{
  padding: 0px !important;
  display: flex !important;
}

div.fade.modal-backdrop {
  z-index: 1050;
  height: 100%;
}

.dropdown-menu.show {
  box-shadow: 0px 8px 24px rgba(0, 0, 0, 0.1);
}

.modal-dialog {
  @media (min-width: 576px) {
  max-width: unset;
  }
}